/* eslint-disable no-mixed-spaces-and-tabs */
// Defaults
import {
	computeLabel,
	isDescriptionHidden,
	isPlainLabel,
	or,
	rankWith,
	schemaTypeIs,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Hidden } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import CustomTextField from './customComps/TextField';

const InputBox = function ({
	id,
	errors,
	label,
	schema,
	description,
	enabled,
	visible,
	required,
	path,
	handleChange,
	data,
	config,
	uischema,
}) {
	const [isFocused, setIsFocused] = React.useState(false);
	const appliedUiSchemaOptions = merge({}, config, uischema.options);
	const [showAzentErrors, setShowAzentErrors] = React.useState(
		appliedUiSchemaOptions.showFormErrors
	);
	const showDescription = !isDescriptionHidden(
		visible,
		description,
		isFocused,
		appliedUiSchemaOptions.showUnfocusedDescription
	);
	const isValid = errors.length === 0;
	const fieldType = schema.format
		? schema.format
		: schema.type == 'number'
		? 'number'
		: 'text';
	const inputLabelProps =
		['date', 'time'].includes(fieldType) ||
		(schema.type == 'number' && data === 0)
			? { shrink: true }
			: { shrink: true };

	const inputProps = {
		min: schema.minLength,
		max: schema.maxLength,
	};

	const onChange = (ev) => {
		if (
			schema.maxLength &&
			appliedUiSchemaOptions.type == 'number' &&
			!isEmpty(ev.target.value)
		) {
			return handleChange(
				path,
				Math.max(0, Number(ev.target.value))
					.toString()
					.slice(0, schema.maxLength)
			);
		}
		handleChange(
			path,
			schema.type == 'number' && !isEmpty(ev.target.value)
				? Number(ev.target.value)
				: ev.target.value.length
				? ev.target.value
				: undefined
		);
	};
	const getErrors = function () {
		if (errors === 'is a required property') {
			return 'Field is required';
		}
		if (errors === 'should match format "email"') {
			return 'Enter a valid email';
		}
		return errors;
	};

	React.useEffect(() => {
		setShowAzentErrors(appliedUiSchemaOptions.showFormErrors);
	}, [config]);

	return (
		<Hidden xsUp={!visible}>
			<CustomTextField
				style={{ textAlign: 'left' }}
				id={id}
				label={computeLabel(
					isPlainLabel(label) ? label : label.default,
					required,
					appliedUiSchemaOptions.hideRequiredAsterisk
				)}
				error={!isValid && showAzentErrors}
				disabled={!enabled || appliedUiSchemaOptions.readOnly}
				fullWidth={!appliedUiSchemaOptions.trim}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				helperText={
					!isValid && showAzentErrors
						? getErrors()
						: showDescription
						? description
						: null
				}
				value={[null, undefined].includes(data) ? '' : data}
				onChange={onChange}
				onWheel={(e) => e.target.blur()}
				variant="outlined"
				type={appliedUiSchemaOptions.type || fieldType}
				InputLabelProps={inputLabelProps}
				multiline={appliedUiSchemaOptions.multi}
				rows={appliedUiSchemaOptions.rows ? appliedUiSchemaOptions.rows : 5}
				inputProps={inputProps}
			/>
		</Hidden>
	);
};

InputBox.propTypes = {
	id: PropTypes.any,
	errors: PropTypes.any,
	label: PropTypes.any,
	schema: PropTypes.any,
	description: PropTypes.any,
	enabled: PropTypes.any,
	visible: PropTypes.any,
	required: PropTypes.any,
	path: PropTypes.any,
	handleChange: PropTypes.any,
	data: PropTypes.any,
	config: PropTypes.any,
	uischema: PropTypes.any,
};

const tester = rankWith(
	10, //increase rank as needed
	or(schemaTypeIs('string'), schemaTypeIs('number'))
);

export default withJsonFormsControlProps(InputBox);
export { tester as InputBoxTester };
