// Defaults
import {
	findUISchema,
	isObjectControl,
	isPlainLabel,
	rankWith
} from '@jsonforms/core';
import {
	areEqual,
	ctxDispatchToControlProps,
	ctxToControlWithDetailProps,
	ResolvedJsonFormsDispatch,
	withJsonFormsContext
} from '@jsonforms/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Hidden, Typography } from '@mui/material';
import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
// Styles
import { useStyles } from './style';

const MaterialObjectRenderer = ({
	renderers,
	cells,
	uischemas,
	schema,
	label,
	path,
	visible,
	enabled,
	uischema,
	rootSchema,
	config,
	errors,
	data,
	handleChange,
}) => {
	const { classes } = useStyles();

	const appliedUiSchemaOptions = merge({}, config, uischema.options);
	const [showAzentErrors, setShowAzentErrors] = React.useState(
		appliedUiSchemaOptions.showFormErrors
	);
	const detailUiSchema = useMemo(
		() =>
			findUISchema(
				uischemas,
				schema,
				uischema.scope,
				path,
				'Group',
				uischema,
				rootSchema
			),
		[uischemas, schema, uischema.scope, path, uischema, rootSchema]
	);
	let errorsToShow =
		showAzentErrors && errors === 'is a required property'
			? 'Section is required'
			: null;

	if (isEmpty(path)) {
		detailUiSchema.type = 'VerticalLayout';
	} else {
		detailUiSchema.label = startCase(
			isPlainLabel(label) ? label : label.default
		);
	}

	if (data && appliedUiSchemaOptions['ensureData']) {
		let ensureData = appliedUiSchemaOptions['ensureData'];
		let newData = { ...data };
		let hasDataChanged = false;
		Object.keys(ensureData).forEach((key) => {
			if (!newData[key]) {
				hasDataChanged = true;
				newData[key] = ensureData[key];
			}
		});
		if (hasDataChanged) {
			handleChange(path, newData);
		}
	}

	React.useEffect(() => {
		setShowAzentErrors(appliedUiSchemaOptions.showFormErrors);
	}, [config]);

	if (appliedUiSchemaOptions.expandable) {
		return (
			<Hidden xsUp={!visible}>
				<Box padding={1}>
					<ExpansionPanel defaultExpanded>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
							<Box>
								<Typography
									variant={'h2'}
									color={errorsToShow ? 'error' : 'initial'}
									className={`${classes.sectionLabel} ${
										appliedUiSchemaOptions.highlightLabel &&
										classes.highlightedLabel
									}`}
								>
									{label || uischema.label}
								</Typography>
								{errorsToShow && (
									<Typography
										color={errorsToShow ? 'error' : 'initial'}
										variant="caption"
										component="div"
									>
										{`(${errorsToShow})`}
									</Typography>
								)}
							</Box>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<ResolvedJsonFormsDispatch
								visible={visible}
								enabled={enabled}
								schema={schema}
								uischema={detailUiSchema}
								path={path}
								renderers={renderers}
								cells={cells}
							/>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</Box>
			</Hidden>
		);
	}
	return (
		<Hidden xsUp={!visible}>
			<Typography
				variant={'h2'}
				color={errorsToShow ? 'error' : 'initial'}
				className={`${classes.sectionLabel} ${
					appliedUiSchemaOptions.highlightLabel && classes.highlightedLabel
				}`}
			>
				{label || uischema.label}
			</Typography>
			{errorsToShow && (
				<Box position="relative" left="5px">
					<Typography
						color={errorsToShow ? 'error' : 'initial'}
						variant="caption"
						component="div"
					>
						{`(${errorsToShow})`}
					</Typography>
				</Box>
			)}
			<ResolvedJsonFormsDispatch
				visible={visible}
				enabled={enabled}
				schema={schema}
				uischema={detailUiSchema}
				path={path}
				renderers={renderers}
				cells={cells}
			/>
		</Hidden>
	);
};

MaterialObjectRenderer.propTypes = {
	id: PropTypes.any,
	errors: PropTypes.any,
	label: PropTypes.any,
	schema: PropTypes.any,
	enabled: PropTypes.any,
	visible: PropTypes.any,
	required: PropTypes.any,
	path: PropTypes.any,
	handleChange: PropTypes.any,
	data: PropTypes.any,
	config: PropTypes.any,
	uischema: PropTypes.any,
	addItem: PropTypes.any,
	cells: PropTypes.any,
	rootSchema: PropTypes.any,
	renderers: PropTypes.any,
	uischemas: PropTypes.any,
};

export const ObjectControlTester = rankWith(11, isObjectControl);

const withContextToDetailProps = (Component) => {
	const ComponentWithContext = ({ ctx, props }) => {
		const detailProps = ctxToControlWithDetailProps(ctx, props);
		const { handleChange } = ctxDispatchToControlProps(ctx.dispatch);
		return <Component {...detailProps} handleChange={handleChange} />;
	};

	return ComponentWithContext;
};

const withJsonFormsDetailProps = (Component) =>
	withJsonFormsContext(
		withContextToDetailProps(
			// eslint-disable-next-line react/display-name
			React.memo(Component, (prevProps, nextProps) => {
				return areEqual(prevProps, nextProps);
			})
		)
	);

export default withJsonFormsDetailProps(MaterialObjectRenderer);
