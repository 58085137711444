// Material
import { makeStyles } from '@mui/styles'

// Styles
export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(4)
    },
    previewFilesContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xs')]: {
            display: 'column'
        }
    },
    categoryContainer: {
        width: '33%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    categoryLabel: {
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom: '14px'
    },
    fileConatiner: {
        marginBottom: '10px',
        width: '230px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    errorMessage: {
        margin: "3px 14px 0"
    }
}))
