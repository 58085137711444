// Dropzone config
export const ALLOWED_FORMATS =
    'image/*,application/pdf,application/vnd.oasis.opendocument.text,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/gzip,application/vnd.rar,application/zip,application/x-7z-compressed'
export const MAX_FILE_SIZE = 5242880
export const MAX_FILES_COUNT = 15

// General
export const FILE_NAME_STRIKE_LENGTH = 30
export const MAX_FILE_SIZE_IN_MB = MAX_FILE_SIZE / (1024 * 1024)

// Errors and codes
export const LARGE_FILE_ERROR = 'file-too-large'
export const LARGE_FILE_ERROR_MSG = `File can't be larger than ${MAX_FILE_SIZE_IN_MB} MB, try removing this file.`

export const FILE_LIMIT_ERROR = 'too-many-files'
export const FILE_LIMIT_ERROR_MSG = `Max files limit is ${MAX_FILES_COUNT}, try removing some files.`

export const FILE_ALREADY_EXISTS = 'file-already-exists'
export const FILE_ALREADY_EXISTS_MSG =
    'Already saved file, try removing this file or remove the saved file.'

export const UNIQUE_CATEGORY = 'unique-category'
export const UNIQUE_CATEGORY_MSG =
    "Already selected category, cann't be selected again."
