// Material
import { makeStyles } from '@mui/styles'

// Styles
export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.common.white,
        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.25)',
        borderRadius: '31px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            height: '95%',
            width: '100%',
            margin: '0px 16px',
            borderRadius: '20px'
        }
    },
    modalContainer: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
            height: '94%',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    dropzoneContainer: {
        [theme.breakpoints.down('xs')]: {
            height: '17%',
            minHeight: '17%',
            overflow: 'scroll'
        }
    },

    uploadFileListContainer: {
        [theme.breakpoints.down('xs')]: {
            overflow: 'scroll',
            height: 'auto',
            minHeight: '67%'
        }
    },
    uploadFileContainer: {},
    processNote: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    noteIcon: {
        fill: '#F16621',
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            width: '15px'
        }
    },
    processNoteLabel: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px'
        }
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
            height: '8%',
            minHeight: '8%',
            justifyContent: 'space-between'
        }
    },
    cancelButton: {
        marginRight: theme.spacing(3),
        minWidth: theme.spacing(15),
        color: '#A9A9A9'
    }
}))
