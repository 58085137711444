/* eslint-disable no-mixed-spaces-and-tabs */
// Defaults
import {
	computeLabel,
	isDescriptionHidden,
	isEnumControl,
	isPlainLabel,
	rankWith,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Clear } from '@mui/icons-material';
import {
	Box,
	FormControl,
	FormHelperText,
	Hidden,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CustomInput } from './customComps/TextField';

// Styles
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
	display: {
		visibility: 'visibile',
	},
	noDisplay: {
		display: 'none',
	},
	questionOnMobile: {
		whiteSpace: 'normal',
		position: 'relative',
		marginBottom: '-22px',
		textAlign: 'left',
	},
	selectAdornment: {
		marginRight: '8px',
	},
}));

const SelectBox = function ({
	id,
	errors,
	label,
	schema,
	description,
	enabled,
	visible,
	required,
	path,
	handleChange,
	data,
	config,
	uischema,
}) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const [isFocused, setIsFocused] = React.useState(false);
	const isValid = errors.length === 0;
	const appliedUiSchemaOptions = merge({}, config, uischema.options);
	const [showAzentErrors, setShowAzentErrors] = React.useState(
		appliedUiSchemaOptions.showFormErrors
	);
	const showDescription = !isDescriptionHidden(
		visible,
		description,
		isFocused,
		appliedUiSchemaOptions.showUnfocusedDescription
	);
	const enumOptions = appliedUiSchemaOptions.enumOptions || schema.enum || [];
	const fieldType = schema.format
		? schema.format
		: schema.type == 'number'
		? 'number'
		: 'text';
	let inputLabelProps = ['date', 'time'].includes(fieldType)
		? { shrink: true }
		: {};

	const onChange = (ev) => handleChange(path, ev.target.value);
	const onDeleteValue = () => handleChange(path, undefined);
	const getErrors = function () {
		if (
			errors === 'is a required property' ||
			errors ===
				'should be string\nshould be equal to one of the allowed values'
		) {
			return 'Field is required';
		}
		return errors;
	};

	const selectConfig = {};
	if (appliedUiSchemaOptions.limitDropdownHeight) {
		selectConfig.MenuProps = { sx: { maxHeight: '275px' } };
	}

	React.useEffect(() => {
		setShowAzentErrors(appliedUiSchemaOptions.showFormErrors);
	}, [config]);

	return (
		<Hidden xsUp={!visible}>
			{appliedUiSchemaOptions.questionOnMobile && isMobile && (
				<Box textAlign="left">
					<Typography
						className={
							appliedUiSchemaOptions.questionOnMobileClassName ||
							classes.questionOnMobile
						}
					>
						{computeLabel(
							isPlainLabel(label) ? label : label.default,
							required,
							appliedUiSchemaOptions.hideRequiredAsterisk
						)}
					</Typography>
				</Box>
			)}
			<FormControl
				fullWidth={!appliedUiSchemaOptions.trim}
				variant="standard"
				error={!isValid && showAzentErrors}
			>
				<InputLabel shrink id={id + '-input-label'} {...inputLabelProps}>
					{appliedUiSchemaOptions.questionOnMobile && isMobile
						? ''
						: computeLabel(
								isPlainLabel(label) ? label : label.default,
								required,
								appliedUiSchemaOptions.hideRequiredAsterisk
						  )}
				</InputLabel>

				<Select
					style={{ textAlign: 'left' }}
					id={id + '-input'}
					labelId={id + '-input-label'}
					disabled={!enabled}
					input={
						<CustomInput
							endAdornment={
								data && appliedUiSchemaOptions.showClearIcon ? (
									<InputAdornment
										position="end"
										className={classes.selectAdornment}
									>
										<IconButton onClick={onDeleteValue}>
											<Clear color="inherit" fontSize="small" />
										</IconButton>
									</InputAdornment>
								) : null
							}
						/>
					}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					value={data || ''}
					onChange={onChange}
					variant="outlined"
					type={fieldType}
					{...selectConfig}
					classes={{
						icon: classes.selectIcon,
					}}
				>
					{enumOptions.map((option) => (
						<MenuItem
							key={option.label || option}
							value={option.value || option}
						>
							{option.label || option}
						</MenuItem>
					))}
				</Select>
				<FormHelperText id={id + 'helper-text'} sx={{ color: 'red' }}>
					{!isValid && showAzentErrors
						? getErrors()
						: showDescription
						? description
						: null}
				</FormHelperText>
			</FormControl>
		</Hidden>
	);
};

SelectBox.propTypes = {
	id: PropTypes.any,
	errors: PropTypes.any,
	label: PropTypes.any,
	schema: PropTypes.any,
	description: PropTypes.any,
	enabled: PropTypes.any,
	visible: PropTypes.any,
	required: PropTypes.any,
	path: PropTypes.any,
	handleChange: PropTypes.any,
	data: PropTypes.any,
	config: PropTypes.any,
	uischema: PropTypes.any,
};

const tester = rankWith(
	11, //increase rank as needed
	isEnumControl
);

export default withJsonFormsControlProps(SelectBox);
export { tester as SelectBoxTester };
