// Material
import { makeStyles } from '@mui/styles'

// Styles
export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    fileDescription: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer'
    },
    fileIcon: {
        width: '20px',
        height: '20px',
        background: '#BED3FF',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    documentIcon: {
        fill: '#FFFFFF',
        width: '12px'
    },
    fileName: {
        marginLeft: '5px',
        color: '#6D6D6D',
        fontWeight: theme.typography.fontWeightMedium
    },
    closeIcon: {
        fill: '#FBC4AC',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fill: '#F98084',
            width: '15px'
        }
    }
}))
