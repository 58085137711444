// Defaults
import {
	FormControl,
	FormHelperText,
	InputBase,
	InputLabel,
} from '@mui/material';
// Material
import { withStyles } from 'tss-react/mui';
export const CustomInput = withStyles(InputBase, (theme) => ({
	root: {
		borderRadius: '10px',
		position: 'relative',
		border: '1px solid #ced4da',
		padding: '6px 12px',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		fontFamily: ['Montserrat'].join(','),
		fontSize: 14,
		fontWeight: 500,
	},
}));

export default function CustomTextField(props) {
	const {
		id,
		label,
		error,
		disabled,
		fullWidth,
		helperText,
		InputLabelProps,
		multiline,
		rows,
		InputProps,
		inputProps,
		// eslint-disable-next-line no-unused-vars
		getOptionSelected,
		// eslint-disable-next-line no-unused-vars
		inputRef,
		...allProps
	} = props;
	return (
		<>
			<FormControl
				{...allProps}
				fullWidth={fullWidth}
				disabled={disabled}
				error={error}
				id={id + '-wrapper'}
				variant="standard"
			>
				<InputLabel {...InputLabelProps} shrink htmlFor={props.id + '-input'}>
					{label}
				</InputLabel>
				<InputBase
					sx={{
						padding: '6px 12px',
						fontSize: '14px',
						fontWeight: '500',
						borderRadius: 0.7,
						border: '1px solid #ced4da',
					}}
					id={props.id + '-input'}
					multiline={multiline}
					minRows={rows}
					fullWidth={true}
					{...allProps}
					{...InputProps}
					inputProps={inputProps}
				></InputBase>
				<FormHelperText id={props.id + 'helper-text'} sx={{ color: 'red' }}>
					{helperText}
				</FormHelperText>
			</FormControl>
		</>
	);
}
