import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import eachOfSeries from 'async/eachOfSeries';

// Material
import {
    Dialog,
    Box,
    Typography,
    useTheme,
    useMediaQuery,
    Button,
} from '@mui/material';

// Components
import { Dropzone } from '../Dropzone';
import { FilePreview } from '../FilePreview';

// Constants
const ADD_FILES = 'ADD_FILES';
const REMOVE_FILE = 'REMOVE_FILE';
const REMOVE_ALL = 'REMOVE_ALL';
import {
    FILE_ALREADY_EXISTS,
    UNIQUE_CATEGORY,
    UNIQUE_CATEGORY_MSG,
} from '../../constants';

// Utils
import {
    getAddConfigForFile,
    getResourceObj,
    getEvaporateConfig,
    createEvaporate,
} from '@/utils/file-uploader';
import { getTheErrorsMsg } from '../../utils';
import ErrorIcon from '@material-ui/icons/Error';

// Styles
import { useStyles } from './style';

export const UploadModal = ({
    open,
    onClose,
    onSave,
    dropzoneConfig,
    uploadConfig,
    savedFileList,
    initialFileList,
    selectCategoryLabel,
    uniqueCategory,
    fileCategories,
    defaultCategory,
    autoSave
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [selectedFileList, setSelectedFileList] = useState(initialFileList);
    const [uploadFileList, setUploadFileList] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [evaporate, setEvaporate] = useState(null);
    const [progress, setProgress] = useState(0);
    const [currentFile, setCurrentFile] = useState(null);
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const onFileProgressChange = (progress) => {
        setProgress(progress * 100);
    };

    const onSaveModal = () => {
        let data = resourceList;
        data = data.map((rFile) => {
            let alreadyExistInUploadList = fileAlreadyAddedToUploadFileList(
                rFile.original_file_name
            );
            rFile.category =
                uploadFileList[alreadyExistInUploadList.index].category;
            return rFile;
        });
        resetUploadModal();
        onSave && onSave(data);
    };

    const onCloseModal = () => {
        resetUploadModal();
        onClose && onClose();
    };

    const resetUploadModal = () => {
        uploadInProgress && evaporate && evaporate.cancel();
        setUploadInProgress(false);
        operationsOnSelectedFileList(REMOVE_ALL, {});
        setResourceList([]);
        setProgress(0);
        setCurrentFile(null);
    };

    const onSelect = (index, category) => {
        // Change the category in uploadFileList
        setUploadFileList((data) => {
            if (uniqueCategory) {
                if (
                    !data[index].isError ||
                    (data[index].isError &&
                        data[index].errorMsg === UNIQUE_CATEGORY_MSG)
                ) {
                    if (
                        isCategoryAlreadySelected(category, [
                            ...data,
                            ...savedFileList,
                        ])
                    ) {
                        data[index].isError = true;
                        data[index].errorMsg = getTheErrorsMsg(UNIQUE_CATEGORY);
                    } else {
                        data[index].isError = false;
                        data[index].errorMsg = '';
                    }
                }
            }

            data[index].category = category;
            return [...data];
        });
    };

    const onCancel = (fileName) => {
        // Remove from the selectedFilesList
        operationsOnSelectedFileList(REMOVE_FILE, { fileName });

        // Remove from the resourceList
        let checkResourceData = fileAlreadyAddedToResourceList(fileName);
        if (checkResourceData.exists) {
            setResourceList((data) => {
                data.splice(checkResourceData.index, 1);
                return [...data];
            });
        }
    };

    const isCategoryAlreadySelected = (category, fileList) => {
        let selectedCategories = fileList.map((file) => file.category);
        return selectedCategories.includes(category);
    };

    const findIndexOfFileInList = (list, fileName, key = 'name') => {
        if (!list || !list.length) return -1;
        return list.findIndex((file) => file[key] === fileName);
    };

    const fileAlreadyAddedToSelectedFileList = (fileName) => {
        let index = findIndexOfFileInList(selectedFileList, fileName);
        return {
            index,
            exists: !(index === -1),
        };
    };

    const fileAlreadyAddedToUploadFileList = (fileName) => {
        let index = findIndexOfFileInList(uploadFileList, fileName);
        return {
            index,
            exists: !(index === -1),
        };
    };

    const fileAlreadyAddedToResourceList = (fileName) => {
        let index = findIndexOfFileInList(
            resourceList,
            fileName,
            'original_file_name'
        );
        return {
            index,
            exists: !(index === -1),
        };
    };

    const fileAlreadyAddedToSaveList = (fileName) => {
        let index = findIndexOfFileInList(
            savedFileList,
            fileName,
            'original_file_name'
        );
        return {
            index,
            exists: !(index === -1),
        };
    };

    const getFileObjectAndError = (file) => {
        let errorCode = file.errors && file.errors[0].code;
        let fileData = errorCode ? file.file : file;
        return { errorCode, fileData };
    };

    const operationsOnSelectedFileList = (
        operation,
        { fileList, fileName }
    ) => {
        let data = [];

        switch (operation) {
            case ADD_FILES: {
                data = fileList.filter((file) => {
                    let { fileData } = getFileObjectAndError(file);
                    return !fileAlreadyAddedToSelectedFileList(fileData.name)
                        .exists;
                });
                data = [...selectedFileList, ...data];
                break;
            }
            case REMOVE_FILE: {
                data = selectedFileList;
                data = data.filter((file) => {
                    let { fileData } = getFileObjectAndError(file);
                    return fileData.name !== fileName;
                });
                break;
            }
            case REMOVE_ALL: {
                data = [];
            }
        }

        setSelectedFileList(data);
    };

    const disableSaveButton = () => {
        return (
            uploadInProgress ||
            !!uploadFileList.filter((uFile) => uFile.isError || !uFile.category)
                .length
        );
    };

    const getResourceFormatFromUploadedFile = useCallback(
        getResourceObj(uploadConfig),
        [uploadConfig]
    );

    useEffect(() => {
        if (!evaporate) {
            let { s3Postfix, awsConfig, selfAuthApiCall } = uploadConfig;
            let evaporateConfig = getEvaporateConfig({
                awsConfig,
                s3Postfix,
                selfAuthApiCall,
            });

            createEvaporate(evaporateConfig, (evaporate) => {
                setEvaporate(evaporate);
            });
        }
    }, []);

    useEffect(() => {
        let data = [];

        if (selectedFileList && selectedFileList.length) {
            data = selectedFileList.map((sFile) => {
                let { errorCode, fileData } = getFileObjectAndError(sFile);
                let fData;
                let alreadyExistInUploadList = fileAlreadyAddedToUploadFileList(
                    fileData.name
                );
                let alreadyExistInSavedList = fileAlreadyAddedToSaveList(
                    fileData.name
                );

                if (alreadyExistInUploadList.exists) {
                    fData = uploadFileList[alreadyExistInUploadList.index];
                } else {
                    fData = {
                        name: fileData.name,
                        url: window.URL.createObjectURL(fileData),
                        type: fileData.type,
                        category: defaultCategory || '',
                        inProgress: false,
                        isCompleted: false,
                        isCancelled: false,
                        isError: false,
                        errorMsg: null,
                        addConfig: getAddConfigForFile(
                            fileData,
                            onFileProgressChange
                        ),
                    };

                    if (
                        (errorCode && errorCode.length) ||
                        alreadyExistInSavedList.exists
                    ) {
                        fData.isError = true;
                        fData.errorMsg = getTheErrorsMsg(errorCode);

                        if (alreadyExistInSavedList.exists) {
                            fData.errorMsg =
                                getTheErrorsMsg(FILE_ALREADY_EXISTS);
                            fData.selectDisabled = true;
                            fData.category =
                                savedFileList[
                                    alreadyExistInSavedList.index
                                ].category;
                        }
                    }
                }

                return fData;
            });
        }

        setUploadFileList(data);
    }, [selectedFileList]);

    useEffect(() => {
        let data = [];

        if (evaporate && uploadFileList && uploadFileList.length) {
            if (uploadInProgress) return;

            setUploadInProgress(true);

            eachOfSeries(
                uploadFileList,
                (uFile, index, next) => {
                    let alreadyExistInResourceList =
                        fileAlreadyAddedToResourceList(uFile.name);
                    let alreadyExistInSavedList = fileAlreadyAddedToSaveList(
                        uFile.name
                    );

                    if (
                        alreadyExistInSavedList.exists ||
                        alreadyExistInResourceList.exists ||
                        uploadFileList[index].inProgress ||
                        uploadFileList[index].isCompleted ||
                        uploadFileList[index].isCancelled ||
                        uploadFileList[index].isError
                    )
                        return next();

                    uploadFileList[index].inProgress = true;

                    setUploadFileList(uploadFileList);
                    setCurrentFile(index);
                    setProgress(0);

                    evaporate.add(uploadFileList[index].addConfig, {}).then(
                        (awsKey) => {
                            uploadFileList[index].isCompleted = true;
                            uploadFileList[index].inProgress = false;
                            setUploadFileList(uploadFileList);

                            data.push(
                                getResourceFormatFromUploadedFile(uFile, awsKey)
                            );
                            next();
                        },
                        () => {
                            uploadFileList[index].isError = true;
                            uploadFileList[index].errorMsg = getTheErrorsMsg();
                            // localTempFiles[index].inProgress = false;
                            setUploadFileList(uploadFileList);
                            next();
                        }
                    );
                },
                () => {
                    setUploadInProgress(false);
                    setCurrentFile(null);
                    if (data.length) {
                        setResourceList((resourceList) => [
                            ...resourceList,
                            ...data,
                        ]);
                    }
                }
            );
        }
    }, [uploadFileList, evaporate]);

    const onDrop = (acceptedFiles, fileRejections) => {
        let fileList = [...acceptedFiles, ...fileRejections];
        operationsOnSelectedFileList(ADD_FILES, {
            fileList,
        });
    };

    useEffect(() => {
        if (
            resourceList.length > 0 &&
            autoSave &&
            progress === 100 &&
            !disableSaveButton()
        )
            onSaveModal();
    }, [progress, uploadInProgress, resourceList])

    return (
        <Dialog
            open={open}
            maxWidth={'md'}
            fullWidth
            classes={{ paper: classes.root }}
        >
            <Box className={classes.modalContainer}>
                <Box className={classes.dropzoneContainer}>
                    <Dropzone
                        label={dropzoneConfig.label}
                        labelHighlight={dropzoneConfig.labelHighlight}
                        config={{
                            ...dropzoneConfig.config,
                            disabled: uploadInProgress,
                        }}
                        onDrop={onDrop}
                    ></Dropzone>
                </Box>
                {uploadInProgress && (
                    <Box className={classes.processNote}>
                        <ErrorIcon
                            className={classes.noteIcon}
                            fontSize={'small'}
                        />
                        <Typography
                            variant={'h6'}
                            className={classes.processNoteLabel}
                        >{`${uploadFileList[currentFile].name}  is still getting uploaded...`}</Typography>
                    </Box>
                )}
                <Box className={classes.uploadFileListContainer}>
                    {!!uploadFileList.length &&
                        uploadFileList.map((uFile, index) => (
                            <Box
                                key={index}
                                className={classes.uploadFileContainer}
                            >
                                <FilePreview
                                    index={index}
                                    name={uFile.name}
                                    selectCategoryLabel={selectCategoryLabel}
                                    category={uFile.category}
                                    fileCategories={fileCategories}
                                    selectDisabled={uFile.selectDisabled}
                                    onSelect={onSelect}
                                    cancelDisabled={uploadInProgress}
                                    onCancel={onCancel}
                                    inProgress={uFile.inProgress}
                                    progress={progress}
                                    isCompleted={uFile.isCompleted}
                                    isError={uFile.isError}
                                    errorMsg={uFile.errorMsg}
                                ></FilePreview>
                            </Box>
                        ))}
                </Box>
                {!autoSave && <Box className={classes.actionContainer}>
                    <Button
                        className={classes.cancelButton}
                        onClick={onCloseModal}
                        label={'Cancel'}
                        size={'medium'}
                        variant={isMobile ? 'text' : 'outlined'}
                        color={'inherit'}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={disableSaveButton()}
                        onClick={onSaveModal}
                        label={'Save'}
                        size={'medium'}
                        variant={'contained'}
                        color={'primary'}
                    >
                        Save
                    </Button>
                </Box> }
            </Box>
        </Dialog>
    );
};

UploadModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dropzoneConfig: PropTypes.object.isRequired,
    uploadConfig: PropTypes.object.isRequired,
    initialFileList: PropTypes.array,
    savedFileList: PropTypes.array,
    selectCategoryLabel: PropTypes.string,
    uniqueCategory: PropTypes.bool,
    fileCategories: PropTypes.array.isRequired,
    defaultCategory: PropTypes.string,
};

UploadModal.defaultProps = {
    initialFileList: [],
    selectCategoryLabel: 'Select Document',
    uniqueCategory: false,
    defaultCategory: ''
};
