// Material
import { makeStyles } from '@mui/styles'

// Styles
export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    dropZone: {
        minHeight: theme.spacing(15),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: ({error}) => error ?  "1px solid #f44336" : `1px dashed #AFB5B9`,
        background: '#F0F4F7',
        borderRadius: `10px`,
        marginTop: theme.spacing(2),
        maxWidth: '100%',
        [theme.breakpoints.down('xs')]: {
            minHeight: theme.spacing(4),
            border: '1px solid #E2E2E2',
            borderRadius: '10px'
        }
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'start'
        }
    },
    labelIcon: {
        fill: '#78A5FF',
        [theme.breakpoints.down('xs')]: {
            marginRight: theme.spacing(1),
        }
    },
    dropZoneLabel: {
        fontWeight: theme.typography.fontWeightMedium,
        color: '#6D6C6C',
        marginTop: '4px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0'
        }
    },
    dropzoneIndicator: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dropzoneIcon: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        background: '#E5EAEC',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            fill: '#78A5FF'
        }
    },
    disabled: {
        opacity: 0.5
    },
    highlightText: {
        color: '#F16621',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0'
        }
    },
    label: {
    }
}))
