import { PropTypes } from 'prop-types';
// styles
import { useStyles } from './style';
// comps
import { CustomLinearProgress } from './partial/CustomProgressBar';

export const LinearProgressBar = ({
	completionPercentage,
	classes,
	variant,
}) => {
	const _classes = useStyles();
	return (
		<div className={`${_classes.root} ${classes.cRoot}`}>
			<CustomLinearProgress
				variant={variant}
				value={completionPercentage}
				classes={{ root: classes.cBarRoot, bar: classes.cBar }}
			/>
		</div>
	);
};

LinearProgressBar.propTypes = {
	completionPercentage: PropTypes.number.isRequired,
	classes: PropTypes.object,
	variant: PropTypes.string,
};
LinearProgressBar.defaultProps = {
	classes: {},
	variant: 'determinate',
};
