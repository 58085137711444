import PropTypes from 'prop-types';

// Material
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

// Utils

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

// Components
import { LinearProgressBar } from '@/components/shared/LinearProgressBar';

// Styles
import { useStyles } from './style';

// Constants
import { FILE_NAME_STRIKE_LENGTH } from '../../constants';

export const FilePreview = ({
	name,
	cancelDisabled,
	onCancel,
	inProgress,
	progress,
	isCompleted,
	isError,
	errorMsg,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

	const displayName =
		name && name.length <= FILE_NAME_STRIKE_LENGTH
			? name
			: `${name.substr(0, FILE_NAME_STRIKE_LENGTH - 3)}...`;

	const removeFile = () => !cancelDisabled && onCancel(name);

	return (
		<Box className={classes.root}>
			<Box className={classes.fileDescription}>
				<Box className={classes.labelContainer}>
					<Typography variant={'h6'}>{displayName}</Typography>
					{isCompleted && !isError && (
						<CheckCircleIcon
							className={classes.completedIcon}
							fontSize={'small'}
						/>
					)}

					{inProgress && (
						<Box>
							<LinearProgressBar
								classes={{ cBarRoot: classes.progressBar }}
								profileCompletionPercentage={progress}
							/>
						</Box>
					)}
					{!isMobile && isError && (
						<Box className={classes.errorContainer}>
							<ErrorIcon className={classes.errorIcon} fontSize={'small'} />
							<Typography variant={'subtitle1'} className={classes.errorMsg}>
								{errorMsg}
							</Typography>
						</Box>
					)}
				</Box>
				<Box
					onClick={removeFile}
					className={`${cancelDisabled ? classes.disabled : classes.active}`}
				>
					<RemoveCircleIcon className={classes.cancelIcon} fontSize={'small'} />
				</Box>
			</Box>
			{isMobile && isError && (
				<Box className={classes.errorContainer}>
					<Typography variant={'subtitle1'} className={classes.errorMsg}>
						{errorMsg}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

FilePreview.propTypes = {
	index: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	selectCategoryLabel: PropTypes.string,
	category: PropTypes.string,
	fileCategories: PropTypes.array.isRequired,
	selectDisabled: PropTypes.bool,
	onSelect: PropTypes.func.isRequired,
	cancelDisabled: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	inProgress: PropTypes.bool,
	progress: PropTypes.number,
	isCompleted: PropTypes.bool,
	isError: PropTypes.bool,
	errorMsg: PropTypes.string,
};

FilePreview.defaultProps = {
	selectCategoryLabel: 'Select',
	category: null,
	selectDisabled: false,
	inProgress: false,
	progress: 0,
	isCompleted: false,
	isError: false,
	errorMsg: '',
};
