import * as React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

// Material
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import DescriptionIcon from '@material-ui/icons/Description';

// Styles
import { useStyles } from './style';

export const Dropzone = ({ label, labelHighlight, config, onDrop, error }) => {
    const classes = useStyles({error});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        ...config,
    });

    return (
        <Box className={classes.root}>
            {!isMobile && (
                <Box className={classes.label}>
                    <Typography variant={'h6'}>{label}</Typography>
                    <Typography
                        variant={'h2'}
                        className={classes.highlightText}
                    >
                        {labelHighlight}
                    </Typography>
                </Box>
            )}
            {isMobile && (
                <Box className={classes.labelContainer}>
                    <DescriptionIcon
                        className={classes.labelIcon}
                        fontSize={'medium'}
                    />
                    <Box>
                        <Typography variant={'body1'}>{label}</Typography>

                        <Typography
                            variant={'subtitle2'}
                            className={classes.highlightText}
                        >
                            {labelHighlight}
                        </Typography>
                    </Box>
                </Box>
            )}

            <Box
                {...getRootProps({
                    className: `${classes.dropZone} ${
                        config.disabled && classes.disabled
                    }`,
                })}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <Typography
                        className={classes.dropZoneLabel}
                        variant="subtitle1"
                    >
                        Drop the files here ...
                    </Typography>
                ) : (
                    <>
                        {!isMobile && (
                            <Box className={classes.dropzoneIndicator}>
                                <Box className={classes.dropzoneIcon}>
                                    <DescriptionIcon
                                        className={classes.labelIcon}
                                        fontSize={'large'}
                                    />
                                </Box>
                                <Typography
                                    className={classes.dropZoneLabel}
                                    variant="subtitle1"
                                >
                                    Drag & Drop your Files here
                                </Typography>
                            </Box>
                        )}
                        {isMobile && (
                            <Box className={classes.dropzoneIndicator}>
                                <Typography
                                    className={classes.dropZoneLabel}
                                    variant="subtitle1"
                                >
                                    Browse File
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

Dropzone.propTypes = {
    label: PropTypes.string,
    labelHighlight: PropTypes.string,
    config: PropTypes.object.isRequired,
    onDrop: PropTypes.func.isRequired,
};

Dropzone.defaultProps = {
    label: 'Upload Documents',
    labelHighlight: '',
};
