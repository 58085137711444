// Material
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
					borderRadius: '10px',
					padding: '10px 28px',
				},
				outlined: {
					padding: '10px 28px',
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					margin: '8px 0px',
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					lineHeight: '24px',
					fontSize: '14px',
					transform: 'translateY(-7px)',
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					marginTop: '16px',
					fontSize: '16px!important',
					backgroundColor: '#ffffff',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#F16621',
		},
		secondary: {
			main: '#263238',
		},
		success: {
			main: '#00C853',
		},
	},
	shape: {
		borderRadius: 15,
	},
	typography: {
		fontFamily: '"Montserrat", "Helvetica", "Arial", "sans-serif"',
	},
});

export default theme;
