import PropTypes from 'prop-types';

// Material
import { Box, Typography } from '@mui/material';

// Utils
import CancelIcon from '@material-ui/icons/Cancel';
import DescriptionIcon from '@material-ui/icons/Description';

// Constants
import { FILE_NAME_STRIKE_LENGTH } from '../../constants';

// Styles
import { useStyles } from './style';

export const ResourcePreview = ({ name, url, onCancel }) => {
	const classes = useStyles();
	const displayName =
		name && name.length <= FILE_NAME_STRIKE_LENGTH
			? name
			: `${name.substr(0, FILE_NAME_STRIKE_LENGTH - 3)}...`;

	let onClick = function () {
		window.open(url, '_blank');
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.fileDescription} onClick={onClick}>
				<Box className={classes.fileIcon}>
					<DescriptionIcon
						className={classes.documentIcon}
						fontSize={'small'}
					/>
				</Box>
				<Typography variant="subtitle1" className={classes.fileName}>
					{displayName}
				</Typography>
			</Box>
			<Box onClick={onCancel}>
				<CancelIcon className={classes.closeIcon} fontSize={'small'} />
			</Box>
		</Box>
	);
};

ResourcePreview.propTypes = {
	name: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	onCancel: PropTypes.func.isRequired,
};
