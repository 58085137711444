import constants from '@/constants';
import Evaporate from 'evaporate';
import MD5 from 'js-md5';
import { sha256 as SHA256 } from 'js-sha256';
import { v4 as uuidv4 } from 'uuid';

const md5 = (data) => MD5.create().update(data).base64();
const sha256 = (data) => SHA256.create().update(data).hex();

const getAddConfigForFile = function (file, onProgressChange) {
	let fileExtension = file.name.split('.')[file.name.split('.').length - 1];

	return {
		name: `${uuidv4()}.${fileExtension}`,
		file: file,
		progress: onProgressChange,
		xAmzHeadersAtInitiate: { 'x-amz-acl': 'public-read' },
	};
};

const getResourceObj = ({ s3Postfix }) => {
	return (file, awsObjectKey) => {
		return {
			original_file_name: file.name,
			url:
				constants.awsConfig.s3BucketBaseUrl +
				'/' +
				s3Postfix +
				'/' +
				awsObjectKey,
			objectId: awsObjectKey,
			type: file.type,
			category: file.category,
		};
	};
};

const getSelfAuth = (selfAuthApiCall) => {
	return (signParams, signHeaders, stringToSign, dateString) => {
		return new Promise((resolve, reject) => {
			let data = {
				to_sign: stringToSign,
				sign_params: signParams,
				sign_headers: signHeaders,
				dateTime: dateString,
			};
			selfAuthApiCall(data)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};
};

const getEvaporateConfig = function ({
	awsConfig,
	s3Postfix,
	selfAuthApiCall,
}) {
	return {
		aws_key: awsConfig.awsKey,
		bucket: `${awsConfig.bucket}/${s3Postfix}`,
		cloudfront: false,
		awsRegion: awsConfig.region,
		computeContentMd5: true,
		cryptoMd5Method: function (data) {
			return md5(data);
		},
		cryptoHexEncodedHash256: function (data) {
			return sha256(data);
		},
		customAuthMethod: getSelfAuth(selfAuthApiCall),
	};
};

let createEvaporate = function (config, callback) {
	Evaporate.create(config).then((evaporate) => {
		callback(evaporate);
	});
};

export {
	getAddConfigForFile,
	getResourceObj,
	getEvaporateConfig,
	createEvaporate,
};
