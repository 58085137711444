/* eslint-disable no-mixed-spaces-and-tabs */
// Api
import * as Apis from '@/api';
// Defaults
import {
	and,
	computeLabel,
	isDescriptionHidden,
	isObjectArrayControl,
	isObjectControl,
	isPlainLabel,
	isPrimitiveArrayControl,
	isStringControl,
	optionIs,
	or,
	rankWith,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Hidden, Typography } from '@mui/material';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
// Components
import CustomTextField from './customComps/TextField';

// Styles
const useStyles = makeStyles()(() => ({
	display: {
		visibility: 'visibile',
	},
	noDisplay: {
		display: 'none',
	},
	questionOnMobile: {
		whiteSpace: 'normal',
		position: 'relative',
		marginBottom: '-22px',
		textAlign: 'left',
	},
}));

const SearchSelect = function ({
	id,
	errors,
	label,
	description,
	enabled,
	visible,
	required,
	path,
	handleChange,
	data,
	config,
	uischema,
	schema,
}) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const isStringType = schema.type == 'string';
	const isStringArrayType = isPrimitiveArrayControl(uischema, schema);
	const defaultValue = schema.type == 'array' ? [] : null;
	const multiple = schema.type == 'array';
	const [isFocused, setIsFocused] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const [inputValue, setInputValue] = React.useState('');
	const [valueForAutoSelect, setValueForAutoSelect] =
		React.useState(defaultValue);
	const [loading, setLoading] = React.useState(false);
	const appliedUiSchemaOptions = merge({}, config, uischema.options);
	const [showAzentErrors, setShowAzentErrors] = React.useState(
		appliedUiSchemaOptions.showFormErrors
	);
	const showDescription = !isDescriptionHidden(
		visible,
		description,
		isFocused,
		appliedUiSchemaOptions.showUnfocusedDescription
	);
	const isValid = errors.length === 0;

	const getDataFromValue = function (v) {
		if (isStringArrayType) {
			return v ? v.map((i) => i[uischema.options.valueKey]) : defaultValue;
		}
		return isStringType && v ? v[uischema.options.valueKey] : v;
	};

	const onChange = (ev, value) =>
		handleChange(path, getDataFromValue(value) || defaultValue);

	const fetchOptions = async (value) => {
		let args = JSON.parse(
			JSON.stringify(uischema.options.searchApiArgs).replace(
				/{{search}}/g,
				value || ''
			)
		);
		setLoading(true);
		Apis[uischema.options.searchApi](...args)
			.then((response) => {
				setOptions(response.data);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const getErrors = function () {
		if (errors.includes('should NOT have fewer than')) {
			return (
				'Please select at least ' +
				(schema.minItems === 1 ? '1 item ' : schema.minItems + ' items')
			);
		}
		if (isStringType && errors.includes('should be string')) {
			return 'Field is required';
		}
		return errors;
	};

	React.useEffect(() => {
		setShowAzentErrors(appliedUiSchemaOptions.showFormErrors);
	}, [config]);

	React.useEffect(() => {
		if (isStringArrayType || isStringType) return;
		let optionsToFetch = [];
		if (multiple) {
			data &&
				data.forEach((i) => {
					i[uischema.options.valueKey] &&
						!i[uischema.options.labelKey] &&
						optionsToFetch.push(i[uischema.options.valueKey]);
				});
		} else {
			if (data) {
				data[uischema.options.valueKey] &&
					!data[uischema.options.labelKey] &&
					optionsToFetch.push(data[uischema.options.valueKey]);
			}
		}
		if (optionsToFetch.length && uischema.options.indexApi) {
			let args = JSON.parse(
				JSON.stringify(uischema.options.indexApiArgs).replace(
					/{{ids}}/g,
					optionsToFetch
				)
			);

			Apis[uischema.options.indexApi](...args).then((response) => {
				setLoading(false);
				handleChange(path, multiple ? response.data : response.data[0] || {});
			});
		}
	}, [data]);

	React.useEffect(() => {
		if (isStringType) {
			let v = {};
			v[uischema.options.labelKey] = data;
			v[uischema.options.valueKey] = data;
			setValueForAutoSelect(v);
		} else if (isStringArrayType) {
			let dataToSet = data || defaultValue;
			dataToSet = dataToSet.map((i) => {
				let v = {};
				v[uischema.options.labelKey] = i;
				v[uischema.options.valueKey] = i;
				return v;
			});
			setValueForAutoSelect(dataToSet);
		} else {
			setValueForAutoSelect(data || defaultValue);
		}
	}, [data]);

	return (
		<Hidden xsUp={!visible}>
			<Autocomplete
				classes={{
					clearIndicator:
						data && data.length ? classes.display : classes.noDisplay,
				}}
				id={id + '-' + Math.ceil(Math.random() * 1000)}
				onChange={onChange}
				options={options}
				value={valueForAutoSelect}
				isOptionEqualToValue={(option, value) =>
					option[uischema['options']['valueKey']] ==
					value[uischema['options']['valueKey']]
				}
				getOptionLabel={(option) => {
					if (uischema['options']['labelExp']) {
						if (!option[uischema['options']['valueKey']]) {
							return '';
						}
						let exp = uischema['options']['labelExp'];
						let matches = exp.match(/\{\{(\w+)\}\}/g);
						matches.forEach((match) => {
							let iMatch = match.match(/\{\{(\w+)\}\}/)[1];
							exp = exp.replace(match, option[iMatch] || '');
						});
						return exp;
					}
					return option[uischema['options']['labelKey']] || '';
				}}
				onInputChange={(e, value) => {
					setInputValue(value);
					fetchOptions(value);
				}}
				fullWidth={!appliedUiSchemaOptions.trim}
				multiple={multiple}
				inputValue={inputValue}
				loading={loading}
				disabled={!enabled}
				renderInput={(params) => {
					params.inputProps.autoComplete = 'new-password';
					return (
						<React.Fragment>
							{appliedUiSchemaOptions.questionOnMobile && isMobile && (
								<Box textAlign="left">
									<Typography
										className={
											appliedUiSchemaOptions.questionOnMobileClassName ||
											classes.questionOnMobile
										}
									>
										{computeLabel(
											isPlainLabel(label) ? label : label.default,
											required,
											appliedUiSchemaOptions.hideRequiredAsterisk
										)}
									</Typography>
								</Box>
							)}
							<CustomTextField
								variant="outlined"
								onFocus={() => {
									fetchOptions();
									setIsFocused(true);
								}}
								onBlur={() => {
									setIsFocused(false);
								}}
								helperText={
									!isValid && showAzentErrors
										? getErrors()
										: showDescription
										? description
										: null
								}
								error={!isValid && showAzentErrors}
								label={
									appliedUiSchemaOptions.questionOnMobile && isMobile
										? ''
										: computeLabel(
												isPlainLabel(label) ? label : label.default,
												required,
												appliedUiSchemaOptions.hideRequiredAsterisk
										  )
								}
								fullWidth={!appliedUiSchemaOptions.trim}
								{...params}
							/>
						</React.Fragment>
					);
				}}
			/>
		</Hidden>
	);
};

SearchSelect.propTypes = {
	id: PropTypes.any,
	errors: PropTypes.any,
	label: PropTypes.any,
	schema: PropTypes.any,
	description: PropTypes.any,
	enabled: PropTypes.any,
	visible: PropTypes.any,
	required: PropTypes.any,
	path: PropTypes.any,
	handleChange: PropTypes.any,
	data: PropTypes.any,
	config: PropTypes.any,
	uischema: PropTypes.any,
	isSearchCustom: PropTypes.bool,
};

const tester = rankWith(
	12, //increase rank as needed
	or(
		and(isStringControl, optionIs('component', 'search')),
		and(isObjectControl, optionIs('component', 'search')),
		and(isObjectArrayControl, optionIs('component', 'search')),
		and(isPrimitiveArrayControl, optionIs('component', 'search'))
	)
);

export default withJsonFormsControlProps(SearchSelect);
export { tester as SearchSelectTester };
