/* eslint-disable no-mixed-spaces-and-tabs */
// Defaults
import {
	and,
	computeLabel,
	isDescriptionHidden,
	isPlainLabel,
	isPrimitiveArrayControl,
	optionIs,
	rankWith,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Hidden, Typography } from '@mui/material';
import lodash from 'lodash';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
// Components
import CustomTextField from './customComps/TextField';

// Styles
const useStyles = makeStyles()(() => ({
	display: {
		visibility: 'visibile',
	},
	noDisplay: {
		display: 'none',
	},
	questionOnMobile: {
		whiteSpace: 'normal',
		position: 'relative',
		marginBottom: '-22px',
		textAlign: 'left',
	},
}));

const MultiSelect = function ({
	id,
	errors,
	label,
	schema,
	description,
	enabled,
	visible,
	required,
	path,
	handleChange,
	data,
	config,
	uischema,
}) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const [isFocused, setIsFocused] = React.useState(false);
	const appliedUiSchemaOptions = merge({}, config, uischema.options);
	const [showAzentErrors, setShowAzentErrors] = React.useState(
		appliedUiSchemaOptions.showFormErrors
	);
	let isValid = errors.length === 0;
	const fieldType = schema.format
		? schema.format
		: schema.type == 'number'
		? 'number'
		: 'text';
	const showDescription = !isDescriptionHidden(
		visible,
		description,
		isFocused,
		appliedUiSchemaOptions.showUnfocusedDescription
	);
	const enumOptions = (schema.items && schema.items.enum) || [];

	const onChange = (ev, value) => handleChange(path, value);
	const getErrors = function () {
		if (errors.includes('should NOT have fewer than')) {
			return (
				'Please select at least ' +
				(schema.minItems === 1 ? '1 item ' : schema.minItems + ' items')
			);
		}
		return errors;
	};

	React.useEffect(() => {
		setShowAzentErrors(appliedUiSchemaOptions.showFormErrors);
	}, [config]);

	return (
		<Hidden xsUp={!visible}>
			<Autocomplete
				classes={{
					clearIndicator:
						data && data.length ? classes.display : classes.noDisplay,
				}}
				multiple
				id={id + '-input-' + Math.ceil(Math.random() * 1000)}
				options={enumOptions}
				getOptionLabel={(option) =>
					appliedUiSchemaOptions.enumOptions
						? appliedUiSchemaOptions.enumOptions.filter((i) => {
								if (option === i.value) {
									return true;
								}
								return false;
						  })[0].label
						: uischema.options.labelTransformation
						? lodash[uischema.options.labelTransformation](option)
						: option
				}
				value={data || []}
				onChange={onChange}
				renderInput={(params) => {
					params.inputProps.autoComplete = 'new-password';
					return (
						<React.Fragment>
							{appliedUiSchemaOptions.questionOnMobile && isMobile && (
								<Box textAlign="left">
									<Typography
										className={
											appliedUiSchemaOptions.questionOnMobileClassName ||
											classes.questionOnMobile
										}
									>
										{computeLabel(
											isPlainLabel(label) ? label : label.default,
											required,
											appliedUiSchemaOptions.hideRequiredAsterisk
										)}
									</Typography>
								</Box>
							)}
							<CustomTextField
								{...params}
								label={
									appliedUiSchemaOptions.questionOnMobile && isMobile
										? ''
										: computeLabel(
												isPlainLabel(label) ? label : label.default,
												required,
												appliedUiSchemaOptions.hideRequiredAsterisk
										  )
								}
								onFocus={() => setIsFocused(true)}
								onBlur={() => setIsFocused(false)}
								error={showAzentErrors && !isValid}
								helperText={
									showAzentErrors && !isValid
										? getErrors()
										: showDescription
										? description
										: null
								}
								fullWidth={!appliedUiSchemaOptions.trim}
								disabled={!enabled}
								variant="outlined"
								type={fieldType}
							/>
						</React.Fragment>
					);
				}}
			/>
		</Hidden>
	);
};

MultiSelect.propTypes = {
	id: PropTypes.any,
	errors: PropTypes.any,
	label: PropTypes.any,
	schema: PropTypes.any,
	description: PropTypes.any,
	enabled: PropTypes.any,
	visible: PropTypes.any,
	required: PropTypes.any,
	path: PropTypes.any,
	handleChange: PropTypes.any,
	data: PropTypes.any,
	config: PropTypes.any,
	uischema: PropTypes.any,
};

const tester = rankWith(
	11, //increase rank as needed
	and(isPrimitiveArrayControl, optionIs('component', 'multiSelect'))
);

export default withJsonFormsControlProps(MultiSelect);
export { tester as MultiSelectTester };
