import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'

// Material
import { Box, Typography, Hidden, FormHelperText } from '@mui/material'

// Components
import { Dropzone } from './partials/Dropzone'
import { UploadModal } from './partials/UploadModal'
import { ResourcePreview } from './partials/ResourcePreview'
import { CancelDialogue } from './partials/CancelDialogue'

// Json form
import { rankWith, optionIs, and, schemaTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

// Constants
import { ALLOWED_FORMATS, MAX_FILE_SIZE, MAX_FILES_COUNT } from './constants'

// Styles
import { useStyles } from './style'

let ResourceControl = function ({
    visible,
    data,
    handleChange,
    path,
    label,
    uischema,
    config,
    errors
}) {
    const classes = useStyles()
    const [appliedUiSchemaOptions, setAppliedUiSchemaOptions] = React.useState(
        merge({}, config, uischema.options)
    )
    const dropzoneConfig = {
        multiple: true,
        accept: appliedUiSchemaOptions.allowedFiles || ALLOWED_FORMATS,
        maxSize: MAX_FILE_SIZE,
        maxFiles: MAX_FILES_COUNT
    }

    if("multiple" in appliedUiSchemaOptions) {
        dropzoneConfig.multiple = appliedUiSchemaOptions.multiple;
    }

    const [initialFileList, setInitialFileList] = React.useState([])
    const [showUploadModal, setShowUploadModal] = React.useState(false)
    const [showCancelDialogue, setShowCancelDialogue] = React.useState(false)
    const [removeFileName, setRemoveFileName] = React.useState(null)

    const onDrop = (acceptedFiles, fileRejections) => {
        setInitialFileList([...acceptedFiles, ...fileRejections])
        setShowUploadModal(true)
    }

    const onSaveModal = (resourceList) => {
        let finalData = data ? data : []
        finalData = [...finalData, ...resourceList]
        handleChange(path, finalData)
        resetUploadModalState()
    }
    const onCancelModal = () => {
        resetUploadModalState()
    }

    const resetUploadModalState = () => {
        setInitialFileList([])
        setShowUploadModal(false)
    }

    const resetCancelDialogueState = () => {
        setShowCancelDialogue(false)
        setRemoveFileName(null)
    }

    const onClickRemoveFile = (fileName) => {
        setRemoveFileName(fileName)
        setShowCancelDialogue(true)
    }

    const removeSelectedFile = () => {
        let finalData = data.filter(
            (file) => file.original_file_name !== removeFileName
        )
        handleChange(path, finalData)
        resetCancelDialogueState()
    }

    React.useEffect(() => {
        setAppliedUiSchemaOptions(merge({}, config, uischema.options))
    }, [config, uischema.options])

    
    const isValid = data && data.length > 0 && errors.length === 0;
    // TODO: implement proper get error message function
    const getErrors = () => 'Field is required';
    return (
        <Hidden xsUp={!visible}>
            <Box className={classes.root}>
                <Dropzone
                    label={label}
                    labelHighlight={appliedUiSchemaOptions.labelHighlight}
                    config={dropzoneConfig}
                    error={appliedUiSchemaOptions.showFormErrors && !isValid}
                    onDrop={onDrop}
                ></Dropzone>
                {appliedUiSchemaOptions.showFormErrors && !isValid && (
                    <FormHelperText error={true} className={classes.errorMessage}>{getErrors()}</FormHelperText>
                )}
                {showUploadModal && (
                    <UploadModal
                        open={showUploadModal}
                        onClose={onCancelModal}
                        onSave={onSaveModal}
                        autoSave={appliedUiSchemaOptions.autoSave}
                        dropzoneConfig={{
                            label,
                            config: dropzoneConfig,
                            labelHighlight:
                                appliedUiSchemaOptions.labelHighlight
                        }}
                        uploadConfig={appliedUiSchemaOptions.uploadConfig}
                        savedFileList={data || []}
                        initialFileList={initialFileList}
                        selectCategoryLabel={
                            appliedUiSchemaOptions.selectCategoryLabel
                        }
                        uniqueCategory={appliedUiSchemaOptions.uniqueCategory}
                        fileCategories={appliedUiSchemaOptions.fileCategories}
                        defaultCategory={appliedUiSchemaOptions.defaultCategory}
                    ></UploadModal>
                )}
                {data && !!data.length && (
                    <Box className={classes.previewFilesContainer}>
                        {appliedUiSchemaOptions.fileCategories.map(
                            (item, index) => {
                                let displayFiles = data.filter(
                                    (file) => file.category === item.value
                                );

                                if (!(displayFiles && displayFiles.length)) {
                                    return null
                                }

                                return (
                                    <Box
                                        key={index}
                                        className={classes.categoryContainer}
                                    >
                                        {!appliedUiSchemaOptions.hideCategory && (
                                            <Typography
                                                variant={'h3'}
                                                className={
                                                    classes.categoryLabel
                                                }
                                            >
                                                {item.label}
                                            </Typography>
                                        )}
                                        {displayFiles.map((file, index) => (
                                            <Box
                                                key={index}
                                                className={
                                                    classes.fileConatiner
                                                }
                                            >
                                                <ResourcePreview
                                                    name={
                                                        file.original_file_name
                                                    }
                                                    url={file.url}
                                                    onCancel={() =>
                                                        onClickRemoveFile(
                                                            file.original_file_name
                                                        )
                                                    }
                                                ></ResourcePreview>
                                            </Box>
                                        ))}
                                    </Box>
                                );
                            }
                        )}
                    </Box>
                )}
                {showCancelDialogue && (
                    <CancelDialogue
                        label={`Are you sure you want to remove “${removeFileName}” ?`}
                        open={showCancelDialogue}
                        onConfirm={removeSelectedFile}
                        onClose={resetCancelDialogueState}
                    ></CancelDialogue>
                )}
            </Box>
        </Hidden>
    )
}

ResourceControl.propTypes = {
    id: PropTypes.any,
    errors: PropTypes.any,
    label: PropTypes.any,
    schema: PropTypes.any,
    description: PropTypes.any,
    enabled: PropTypes.any,
    visible: PropTypes.any,
    required: PropTypes.any,
    path: PropTypes.any,
    handleChange: PropTypes.any,
    data: PropTypes.any,
    config: PropTypes.any,
    uischema: PropTypes.any
}

const tester = rankWith(
    15, //increase rank as needed
    and(optionIs('component', 'resource'), schemaTypeIs('array'))
)

export default withJsonFormsControlProps(ResourceControl)
export { tester as ArrayResourceControl }
