// Material
import { makeStyles } from '@mui/styles'

// Styles
export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3)
        }
    },
    fileDescription: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    completedIcon: {
        fill: '#75D675',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            width: '15px'
        }
    },
    errorContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    errorIcon: {
        fill: '#F9454B',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            width: '15px'
        }
    },
    errorMsg: {
        color: '#F9454B',
        fontWeight: theme.typography.fontWeightMedium,
        [theme.breakpoints.down('xs')]: {
            margin: '5px 0',
            fontSize: '11px'
        }
    },
    active: {
        cursor: 'pointer'
    },
    disabled: {
        cursor: 'default',
        opacity: '.5'
    },
    cancelIcon: {
        fill: '#F98084',
        [theme.breakpoints.down('xs')]: {
            width: '15px'
        }
    },
    progressBar: {
        width: '50px',
        height: '6px !important',
        borderRadius: '2px !important',
        marginLeft: theme.spacing(2)
    },
    categoryContainer: {
        marginTop: '5px'
    }
}))
