
import {withStyles } from '@mui/styles';
// material
import { LinearProgress as MuiLinearProgress } from '@mui/material';

export const CustomLinearProgress = withStyles((theme) => ({
	root: {
		height: 8,
		borderRadius: 4,
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[300],
	},
	bar: {
		borderRadius: 4,
		backgroundColor: theme.palette.success.main,
	},
}))(MuiLinearProgress);