// Constants
import {
    LARGE_FILE_ERROR,
    LARGE_FILE_ERROR_MSG,
    FILE_LIMIT_ERROR,
    FILE_LIMIT_ERROR_MSG,
    FILE_ALREADY_EXISTS,
    FILE_ALREADY_EXISTS_MSG,
    UNIQUE_CATEGORY,
    UNIQUE_CATEGORY_MSG
} from './constants'

export const getTheErrorsMsg = (code) => {
    switch (code) {
        case LARGE_FILE_ERROR:
            return LARGE_FILE_ERROR_MSG
        case FILE_LIMIT_ERROR:
            return FILE_LIMIT_ERROR_MSG
        case FILE_ALREADY_EXISTS:
            return FILE_ALREADY_EXISTS_MSG
        case UNIQUE_CATEGORY:
            return UNIQUE_CATEGORY_MSG
        default:
            return 'Some error with the file, try removing this file.'
    }
}
