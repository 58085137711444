// Material
import { makeStyles } from 'tss-react/mui';

// Styles
export const useStyles = makeStyles()((theme) => ({
	sectionLabel: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '17px',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	highlightedLabel: {
		color: '#F16621',
	},
}));
