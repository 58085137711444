// Defaults
import {
	computeLabel,
	isDateControl,
	isDescriptionHidden,
	isPlainLabel,
	rankWith,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import EventIcon from '@mui/icons-material/Event';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Hidden } from '@mui/material';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import merge from 'lodash/merge';
import startsWith from 'lodash/startsWith';
import moment from 'moment';
import PropTypes from 'prop-types';
// Components
import CustomTextField from './customComps/TextField';

function MaterialDateControl({
	description,
	id,
	errors,
	label,
	uischema,
	visible,
	enabled,
	required,
	path,
	handleChange,
	data,
	momentLocale, //eslint-disable-line
	config,
}) {
	const [isFocused, setIsFocused] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const defaultLabel = label;
	const cancelLabel = '%cancel';
	const clearLabel = '%clear';
	const isValid = errors.length === 0;
	const appliedUiSchemaOptions = merge({}, config, uischema.options);
	const [showAzentErrors, setShowAzentErrors] = React.useState(
		appliedUiSchemaOptions.showFormErrors
	);
	const showDescription = !isDescriptionHidden(
		visible,
		description,
		isFocused,
		appliedUiSchemaOptions.showUnfocusedDescription
	);
	const localeDateTimeFormat = 'DD-MM-YYYY';

	let labelText;
	let labelCancel;
	let labelClear;

	if (isPlainLabel(label)) {
		labelText = label;
		labelCancel = 'Cancel';
		labelClear = 'Clear';
	} else {
		labelText = defaultLabel;
		labelCancel = startsWith(cancelLabel, '%') ? 'Cancel' : cancelLabel;
		labelClear = startsWith(clearLabel, '%') ? 'Clear' : clearLabel;
	}

	React.useEffect(() => {
		setShowAzentErrors(appliedUiSchemaOptions.showFormErrors);
	}, [config]);

	const datePickerConfig = {};

	if (appliedUiSchemaOptions.disableDay) {
		datePickerConfig.shouldDisableDate = (date) => {
			if (Array.isArray(appliedUiSchemaOptions.disableDay)) {
				return appliedUiSchemaOptions.disableDay.includes(date.format('dddd'));
			}
			return date.format('dddd') === appliedUiSchemaOptions.disableDay;
		};
	}

	if (appliedUiSchemaOptions.minDate) {
		datePickerConfig.minDate = appliedUiSchemaOptions.minDate;
	}

	if (appliedUiSchemaOptions.minimumDate === 'now') {
		datePickerConfig.minDate = new Date();
	}

	return (
		<Hidden xsUp={!visible}>
			<LocalizationProvider dateAdapter={MomentUtils}>
				<DatePicker
					id={id + '-input'}
					label={computeLabel(
						labelText,
						required,
						appliedUiSchemaOptions.hideRequiredAsterisk
					)}
					inputFormat="DD-MM-YYYY"
					renderInput={(props) => (
						<CustomTextField
							{...props}
							fullWidth
							onClick={(e) => {
								setAnchorEl(e.currentTarget);
								props.onClick && props.onClick(e);
							}}
							error={!isValid && showAzentErrors}
							helperText={
								!isValid && showAzentErrors
									? errors
									: showDescription
									? description
									: null
							}
						/>
					)}
					fullWidth={!appliedUiSchemaOptions.trim}
					InputLabelProps={{ shrink: true }}
					value={data || null}
					openTo={appliedUiSchemaOptions.openTo || 'day'}
					views={['day', 'month', 'year']}
					onChange={(dateTime) =>
						handleChange(
							path,
							dateTime ? moment(dateTime).format('YYYY-MM-DD') : ''
						)
					}
					format={localeDateTimeFormat}
					clearable={true}
					disabled={!enabled}
					autoFocus={appliedUiSchemaOptions.focus}
					onFocus={() => setIsFocused(true)}
					onBlur={() => {
						setIsFocused(false);
					}}
					cancelLabel={labelCancel}
					clearLabel={labelClear}
					leftArrowIcon={<KeyboardArrowLeftIcon />}
					rightArrowIcon={<KeyboardArrowRightIcon />}
					keyboardIcon={<EventIcon />}
					InputProps={{
						autoComplete: 'off',
					}}
					orientation="landscape"
					inputVariant="outlined"
					PopperProps={{
						placement: 'bottom',
						anchorEl: anchorEl,
					}}
					disablePast={appliedUiSchemaOptions.disablePast}
					disableFuture={appliedUiSchemaOptions.disableFuture}
					{...datePickerConfig}
				/>
			</LocalizationProvider>
		</Hidden>
	);
}

MaterialDateControl.propTypes = {
	id: PropTypes.any,
	errors: PropTypes.any,
	label: PropTypes.any,
	schema: PropTypes.any,
	description: PropTypes.any,
	enabled: PropTypes.any,
	visible: PropTypes.any,
	required: PropTypes.any,
	path: PropTypes.any,
	handleChange: PropTypes.any,
	data: PropTypes.any,
	config: PropTypes.any,
	uischema: PropTypes.any,
	momentLocale: PropTypes.any,
};
export const DateControlTester = rankWith(11, isDateControl);

export default withJsonFormsControlProps(MaterialDateControl);
