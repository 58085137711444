// Material
import { makeStyles } from '@mui/styles'

// Styles
export const useStyles = makeStyles((theme) => ({
    root: {
        background: '#FFFFFF',
        boxShadow: '0px 4px 15px rgb(0 0 0 / 25%)',
        borderRadius: '20px'
    },
    dialogueConatiner: {
        maxWidth: '540px',
        minHeight: '150px',
        padding: theme.spacing(4, 3, 3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    label: {},
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    cancelButton: {
        marginRight: theme.spacing(3),
        color: '#A9A9A9'
    }
}))
