// Defaults
import { createAjv } from '@jsonforms/core';
import {
	materialCells,
	materialRenderers,
} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
// Renderers
import DatePickerRenderer, {
	DateControlTester,
} from './customRenderers/datePicker';
import InputBox, { InputBoxTester } from './customRenderers/input';
import MultiSelectRenderer, {
	MultiSelectTester,
} from './customRenderers/multiSelect';
import ObjectRenderer, {
	ObjectControlTester,
} from './customRenderers/objectRenderer';
import ArrayResourceRender, {
	ArrayResourceControl,
} from './customRenderers/resource/array-resource';
import SelectSearch, {
	SearchSelectTester,
} from './customRenderers/searchSelect';
import SelectBox, { SelectBoxTester } from './customRenderers/select';
// Theme
import defaultTheme from './jsonFormTheme';

// Constants
let ajv = createAjv({ $data: true, useDefaults: 'empty' });
require('ajv-keywords')(ajv);
require('ajv-errors')(ajv);

export default function FormComponent({
	schema,
	uiSchema,
	data,
	onChange,
	showFormErrors,
	theme,
	readOnlyForm,
	disabled,
	uploadConfig,
	...props
}) {
	const renderers = [
		...materialRenderers,
		{ tester: InputBoxTester, renderer: InputBox },
		{ tester: SelectBoxTester, renderer: SelectBox },
		{ tester: SearchSelectTester, renderer: SelectSearch },
		{ tester: MultiSelectTester, renderer: MultiSelectRenderer },
		{ tester: SearchSelectTester, renderer: SelectSearch },
		{ tester: DateControlTester, renderer: DatePickerRenderer },
		{ tester: ObjectControlTester, renderer: ObjectRenderer },
		{ tester: ArrayResourceControl, renderer: ArrayResourceRender },
	];
	// TODO: REMOVE ARRAY RESOURCE AND ADD SINGLE RESOURCE RENDERER

	return (
		<ThemeProvider theme={theme || defaultTheme}>
			<JsonForms
				schema={schema}
				uischema={uiSchema}
				data={data}
				renderers={renderers}
				cells={materialCells}
				onChange={({ data, errors }) => onChange({ data, errors })}
				ajv={ajv}
				config={{
					restrict: false,
					trim: false,
					showUnfocusedDescription: false,
					hideRequiredAsterisk: readOnlyForm,
					azentViewOptimised: readOnlyForm,
					showFormErrors,
					disabled,
					uploadConfig,
				}}
				{...props}
			/>
		</ThemeProvider>
	);
}

FormComponent.propTypes = {
	data: PropTypes.object,
	schema: PropTypes.object.isRequired,
	uiSchema: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	theme: PropTypes.object,
	showFormErrors: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	uploadConfig: PropTypes.object,
};

FormComponent.defaultProps = {
	data: {},
	showFormErrors: false,
	theme: null,
	readOnly: false,
	disabled: false,
	uploadConfig: {},
};
