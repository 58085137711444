import * as React from 'react';
import PropTypes from 'prop-types';

// Material
import { Dialog, Box, Typography, Button } from '@mui/material';

// Styles
import { useStyles } from './style';

export const CancelDialogue = ({ label, open, onConfirm, onClose }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
            <Box className={classes.dialogueConatiner}>
                <Typography variant={'h6'}>{label}</Typography>
                <Box className={classes.actionContainer}>
                    <Button
                        className={classes.cancelButton}
                        onClick={onClose}
                        size={'small'}
                        variant={'text'}
                        color={'inherit'}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onConfirm}
                        size={'small'}
                        variant={'outlined'}
                        color={'primary'}
                    >
                        Yes
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

CancelDialogue.propTypes = {
    label: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
